import { formatCurrency } from '@rategravity/core-lib/rates';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox,
  OwnUpModalController,
  OwnUpText
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import {
  BoldSectionText,
  HelperText,
  HighlightHeader,
  SectionText,
  SectionTitle
} from '../../../components/typography';
import { Feedback } from '../../../context';

const TitleIntroStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_SEMIBOLD,
  display: 'block',
  textTransform: 'uppercase',
  letterSpacing: '1.5px',
  marginTop: '25px',
  marginBottom: '10px'
});

const TitleIntro = createOwnUpComponent(OwnUpText, TitleIntroStyle);

const SummaryWrapperStyle = createOwnUpStyle({
  padding: '0 auto',
  textAlign: 'center'
});

const SummaryWrapper = createOwnUpComponent('section', SummaryWrapperStyle);

const HelperTextWrapper = createOwnUpComponent(OwnUpBox, {
  margin: '20px auto'
});

const AverageText = ({ lenderName }: { lenderName: string }) => (
  <SectionText>
    {lenderName}&apos;s average interest rate is about the same relative to the state average.
    Interest rates can vary by more than 1% on the same day, for the same customer.
  </SectionText>
);

const GoodText = ({ lender: { lenderName, percentile } }: Feedback) => (
  <SectionText>
    {lenderName}&apos;s average interest rate is lower than {Math.round(percentile * 100)}% of
    lenders in the state. Interest rates can vary by more than 1% on the same day, for the same
    customer.
  </SectionText>
);

const BadText = ({
  lender: {
    lenderName,
    percentile,
    interestSpread: { annual }
  }
}: Feedback & { good?: boolean }) => (
  <SectionText>
    {lenderName} has <BoldSectionText>higher interest rates</BoldSectionText> than{' '}
    <BoldSectionText>{Math.round(100 - percentile * 100)}%</BoldSectionText> of lenders &mdash; this
    costs its customers an extra {formatCurrency(annual, false)} per year versus the average.
  </SectionText>
);

const Body = (data: Feedback) => {
  const { percentile } = data.lender;

  if (percentile > 0.6) {
    return <GoodText {...data} />;
  } else if (percentile > 0.4) {
    return <AverageText lenderName={data.lender.lenderName} />;
  } else {
    return <BadText {...data} />;
  }
};

const Overview = ({ grade }: { grade: Feedback['lender']['grade'] }) => (
  <>
    <TitleIntro>Compared to others, this lender is...</TitleIntro>
    <SectionTitle level={1}>
      <HighlightHeader>{grade}</HighlightHeader>
    </SectionTitle>
  </>
);

const BareButtonStyle = createOwnUpStyle({
  color: 'inherit',
  backgroundColor: 'inherit',
  font: 'inherit',
  borderWidth: 0,
  padding: 0
});

const BareButton = createOwnUpComponent('button', BareButtonStyle);

const FilterHelper = ({ modalController }: { modalController: OwnUpModalController }) => (
  <HelperTextWrapper>
    <HelperText>
      Calculated from{' '}
      <BareButton
        onClick={modalController.show}
        style={{ cursor: 'pointer', textDecoration: 'underline' }}
      >
        your inputs
      </BareButton>
      .
    </HelperText>
  </HelperTextWrapper>
);

export const Summary = ({
  data,
  modalController
}: {
  data: Feedback;
  modalController: OwnUpModalController;
}) => (
  <SummaryWrapper aria-label="Results Summary">
    <Overview grade={data.lender.grade} />
    <Body {...data}></Body>
    <FilterHelper modalController={modalController} />
  </SummaryWrapper>
);
