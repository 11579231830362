import { formatCurrency } from '@rategravity/core-lib/rates';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox,
  ScreenSize,
  useScreenSize
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { colors } from '../../../modules';
import { Data, LabelChildProps, LabelDerivedProps, LabelGivenProps, ScreenSizeName } from './types';

const LabelTextStyle = ({ xsmallScreen }: { xsmallScreen: boolean }) =>
  createOwnUpStyle({
    ...fonts.GRAPHIK_REGULAR,
    color: colors.WHITE,
    fontSize: '8px',
    lineHeight: '11px',
    padding: '8px',
    ...(xsmallScreen ? { width: '54px' } : { width: '94px' }),
    variants: {
      mediumAndUp: {
        fontSize: '11px',
        lineHeight: '15px',
        padding: '14px',
        width: '130px'
      }
    }
  });

const LabelText = createOwnUpComponent(OwnUpBox, LabelTextStyle);

// Find the X coord for the upper-left corner of the text label on the graph.
// Also used with an `extraOffset` equal to the label's width to find the
// starting point of the line connecting the label to the relevant bar on
// the graph.
const computeXPos = (belowZero: boolean, screenSize: ScreenSizeName, extraOffset: number = 0) => {
  let xPos;
  switch (screenSize) {
    case 'xsmall':
      xPos = belowZero ? 100 : 70 + extraOffset;
    case 'small':
      xPos = belowZero ? 140 : 70 + extraOffset;
    default:
      xPos = belowZero ? 240 : 130 + extraOffset;
  }

  // Graph width is used as a reference for computing tooltip positioning for bars under the median,
  // in which case we want to mirror xPos over to the right side of the graph.
  if (belowZero) {
    const graphWidth = document.getElementById('graph')?.getBoundingClientRect().width;
    xPos = graphWidth! - xPos;
  }

  return xPos;
};

const LabelDot = ({ belowZero, radius, x, y, width }: LabelChildProps) => {
  return (
    <circle
      cx={x + width / 2}
      cy={y - radius + (belowZero ? 14.5 : -5)} // sit label dot just above / below it's bar
      r={radius}
      fill={colors.PRIMARY}
      stroke={colors.GREY}
      strokeWidth={3}
    />
  );
};

const LabelLine = ({
  belowZero,
  lineYPos,
  radius,
  screenSize,
  tooltipWidth: offset,
  x
}: LabelChildProps) => (
  <line
    x1={computeXPos(belowZero, screenSize, offset)}
    x2={x + radius * 2}
    y1={lineYPos}
    y2={lineYPos}
    stroke={colors.PRIMARY}
    strokeWidth="0.6"
    strokeDasharray="1.8 1.8"
  />
);

const LabelBody = ({
  belowZero,
  lineYPos,
  labelHeight,
  lifetimeSavings,
  screenSize,
  tooltipWidth
}: LabelChildProps) => {
  const graphHeight = document.getElementById('graph')?.getBoundingClientRect().height;

  // ensures the label will never cross over the median line and overlap any bars
  const yPos = belowZero
    ? Math.max(lineYPos - labelHeight / 2, graphHeight! / 2 + 15)
    : Math.min(lineYPos - labelHeight / 2, graphHeight! / 2 - labelHeight - 5);

  return (
    <foreignObject
      x={computeXPos(belowZero, screenSize)}
      y={yPos}
      width={tooltipWidth}
      height={100}
    >
      <div
        style={{
          backgroundColor: colors.GREY_FOOTER,
          borderRadius: '2px'
        }}
      >
        <LabelText xsmallScreen={screenSize === 'xsmall'}>
          Loans from this lender {belowZero ? 'cost' : 'save'} an extra{' '}
          <b>{formatCurrency(lifetimeSavings, false)}</b> in interest on average.
        </LabelText>
      </div>
    </foreignObject>
  );
};

const getScreenSizeName = (screenSizePx: ScreenSize) => {
  if (window.screen.width < 360) {
    // Alternate tooltip styling required for very small screens (does not conform to set breakpoints)
    return 'xsmall';
  } else if (screenSizePx < ScreenSize.medium) {
    return 'small';
  }
  return 'large';
};

const getDerivedProps = (screenSizePx: ScreenSize, props: LabelGivenProps): LabelDerivedProps => {
  const screenSize = getScreenSizeName(screenSizePx);
  let labelHeight, tooltipWidth;
  if (screenSize === 'xsmall') {
    labelHeight = 82;
    tooltipWidth = 70;
  } else if (screenSize === 'small') {
    labelHeight = 49;
    tooltipWidth = 110;
  } else {
    labelHeight = 73;
    tooltipWidth = 158;
  }

  // Indicates the bar is in the lower left quadrant of the graph.
  // Has big implications for label positioning.
  const belowZero = props.index < 5;

  // Y axis positioning for dashed line
  const lineYPos = props.y + (belowZero ? 10 : -10);

  return {
    ...props,
    belowZero,
    labelHeight,
    lineYPos,
    tooltipWidth,
    screenSize
  };
};

export const Label = ({
  props,
  data,
  lifetimeSavings
}: {
  props: LabelGivenProps;
  data: Data;
  lifetimeSavings: number;
}) => {
  const screenSizePx = useScreenSize();

  // determine if the label is required for each bar
  const needsLabel = data[props.index].label;

  // Radius of styled dot
  const radius = 4.5;

  // includes the original props, plus calculated values
  const derivedProps = getDerivedProps(screenSizePx, props);

  const childProps: LabelChildProps = {
    lifetimeSavings,
    radius,
    ...derivedProps
  };

  // The label is in 3 parts:
  // 1) the styled dot above / below the targeted bar
  // 2) the dashed line connecting the dot with the tooltip
  // 3) the tooltip which contains some dynamic data
  return needsLabel ? (
    <g>
      <LabelDot {...childProps} />
      <LabelLine {...childProps} />
      <LabelBody {...childProps} />
    </g>
  ) : null;
};
