import React, { PropsWithChildren } from 'react';

export const GetStartedLink = ({
  children,
  label,
  onClick
}: PropsWithChildren<{ label?: string; onClick?: () => void }>) => (
  <a
    style={{ textDecoration: 'none' }}
    href={`${process.env.GATSBY_WWW}/onboarding/get-started`}
    aria-label={label || 'Get Started with Own Up!'}
    onClick={onClick}
  >
    {children}
  </a>
);
