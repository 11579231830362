import {
  colors,
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox,
  OwnUpText
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';

const GraphTitleStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_MEDIUM,
  fontSize: '11px',
  paddingLeft: '20px',
  variants: {
    mediumAndUp: {
      fontSize: '17px'
    }
  }
});

export const GraphTitle = createOwnUpComponent(OwnUpText, GraphTitleStyle);

const WrapperStyle = createOwnUpStyle({
  backgroundColor: colors.WHITE,
  padding: '30px 10px',
  margin: 'auto',
  maxWidth: '630px'
});

export const Wrapper = createOwnUpComponent('section', WrapperStyle);

const GraphWrapperStyle = createOwnUpStyle({
  maxHeight: '575px',
  maxWidth: '575px',
  variants: {
    mediumAndUp: {
      paddingLeft: '20px'
    }
  }
});

export const GraphWrapper = createOwnUpComponent(OwnUpBox, GraphWrapperStyle);

const LegendWrapperStyle = createOwnUpStyle({
  display: 'flex',
  paddingLeft: '10px'
});

export const LegendWrapper = createOwnUpComponent(OwnUpBox, LegendWrapperStyle);

const LegendTextStyle = createOwnUpStyle({
  fontSize: '11px',
  variants: {
    mediumAndUp: {
      fontSize: '15px'
    }
  }
});

export const LegendText = createOwnUpComponent(OwnUpText, LegendTextStyle);

const LegendLabelStyle = createOwnUpStyle({
  borderRadius: '.5px',
  height: '9px',
  marginRight: '5px',
  width: '9px',
  variants: {
    mediumAndUp: {
      height: '15px',
      marginRight: '10px',
      width: '15px'
    }
  }
});

const LegendLabel = createOwnUpComponent(OwnUpBox, LegendLabelStyle);

export const LegendItem = ({ color, text }: { color: string; text: string }) => (
  <div style={{ display: 'flex', padding: '10px' }}>
    <LegendLabel
      style={{
        backgroundColor: color
      }}
    ></LegendLabel>
    <LegendText>{text}</LegendText>
  </div>
);
