import HandshakeSVG from '../../images/handshake.svg';
import MoneySVG from '../../images/money.svg';
import ScaleSVG from '../../images/scale.svg';

const one = 'Hassle-free comparisons';
const two = 'We work for you, not the lender';
const three = 'We offer great rates and terms';

export const valueProps = [
  {
    Image: {
      svg: ScaleSVG,
      imageName: 'scale.svg',
      height: 128
    },
    data: {
      catchphrase: one,
      text: 'Our technology searches thousands of loans from top lenders, credit unions, and mortgage companies. You get accurate, detailed offers to compare, side-by-side.',
      label: one
    }
  },
  {
    Image: {
      svg: HandshakeSVG,
      imageName: 'handshake.svg',
      height: 128
    },
    data: {
      catchphrase: two,
      text: 'We don’t make more money when you pay higher interest and fees. That means you get the best offers from our marketplace, right up front. No haggling necessary.',
      label: two
    }
  },
  {
    Image: {
      svg: MoneySVG,
      imageName: 'money.svg',
      height: 128
    },
    data: {
      catchphrase: three,
      text: 'Lenders pay us less than half of what they typically pay their own salespeople, so we  can offer you rates and terms that typically beat the broader market.',
      label: three
    }
  }
];
