import { UnderlinedLink } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox,
  OwnUpText
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { colors } from '../../../modules';

const MethodologyBodyStyle = createOwnUpStyle({
  color: colors.GREY_FOOTER,
  lineHeight: '170%',
  fontSize: '12px',
  fontWeight: 400
});

const MethodologyBody = createOwnUpComponent(OwnUpText, MethodologyBodyStyle);

const MethodologyHeaderWrapperStyle = createOwnUpStyle({
  margin: '40px 0 20px'
});

const MethodologyHeaderWrapper = createOwnUpComponent(OwnUpBox, MethodologyHeaderWrapperStyle);

const MethodologyTitleStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_MEDIUM,
  color: colors.GREY_FOOTER,
  fontSize: '14px',
  fontWeight: 500
});

const MethodologyTitle = createOwnUpComponent(OwnUpText, MethodologyTitleStyle);

export const Methodology = () => (
  <section aria-label="methodology">
    <MethodologyHeaderWrapper>
      <MethodologyTitle>Methodology</MethodologyTitle>
    </MethodologyHeaderWrapper>
    <MethodologyBody>
      All results are based on Home Mortgage Disclosure Act (HMDA) data (a data set that represents
      all mortgage collection loans originated in the US in 2019 &mdash; think of a row of data for
      each loan). Lender Grader uses a three step process to deliver its results.
      <ol>
        <li>
          Step 1 filters the HMDA data for only those loans that are relevant to you based on your
          inputs of State, Loan Type, Loan Size, and Loan Term (so we don’t compare a 30 year loan
          with a 15 year loan or $200,000 loan with a $2M one or a conventional loan with an FHA
          loan). For each loan we have a rate spread which is the difference between the loan’s
          Annualized Percentage Rate (APR), and the{' '}
          <UnderlinedLink
            href="https://ffiec.cfpb.gov/tools/rate-spread/methodology"
            color={colors.CLASSIC_BLUE}
          >
            Average Prime Offer Rate
          </UnderlinedLink>{' '}
          (APOR, an index which is published weekly), for loans made in the same time period by a
          given lender. The calculated rate spread allows us to assess whether a particular
          originated loan is priced better or worse than the APOR.
        </li>

        <li>
          Since rate spreads are constantly moving, in Step 2 we take the median rate spread of each
          lender, to measure whether a lender’s originated loans tended to price better or worse
          than the APOR.
        </li>

        <li>
          In Step 3, we order all lenders from highest rate spreads (worse terms for borrowers) to
          lowest/negative rate spreads (best terms for borrowers) and “grade” a particular lender
          based on where the lender falls compared to other lenders in the same state for a similar
          loan.
        </li>
      </ol>
    </MethodologyBody>
  </section>
);
