import { useTrackingInstance } from '@rategravity/1pt-lib';
import { FOG_20 } from '@rategravity/own-up-component-library';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { CallToActionButton, CallToActionButtonWrapper } from '../../button';
import { GetStartedLink } from '../../links';
import { SectionText, SectionTitle } from '../../typography';

const WrapperStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: FOG_20,
  marginTop: '35px',
  textAlign: 'center',
  variants: {
    large: {
      maxWidth: '500px'
    },
    smallAndUp: {
      marginRight: '35px',
      marginLeft: '35px'
    }
  }
});

const Wrapper = createOwnUpComponent(OwnUpBox, WrapperStyle);

export const ShopOurMarketplaceCTA = () => {
  const trackingInstance = useTrackingInstance();
  return (
    <section aria-label="Next Steps">
      <Wrapper>
        <SectionTitle variant="secondary">The more you shop, the more you save.</SectionTitle>
        <SectionText>
          Borrowers who shop at multiple lenders save thousands. When it comes to your mortgage,
          don&apos;t just hope that you&apos;re getting a good deal &mdash; make sure.{' '}
        </SectionText>
        <CallToActionButtonWrapper>
          <GetStartedLink
            label="Search Our Marketplace"
            onClick={() => {
              trackingInstance.track('clickedResultsDiscoverCTA-lenderGrader');
              trackingInstance.track('Lender Grader Discover CTA Clicked');
            }}
          >
            <CallToActionButton>Shop Our Marketplace</CallToActionButton>
          </GetStartedLink>
        </CallToActionButtonWrapper>
      </Wrapper>
    </section>
  );
};
