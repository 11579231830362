import { formatCurrency } from '@rategravity/core-lib/rates';
import { ScreenSize, useScreenSize } from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import { colors } from '../../../modules';
import { GraphTitle, GraphWrapper, LegendItem, LegendWrapper, Wrapper } from './components';
import { computeTicks } from './helpers';
import { Label } from './shapes';
import { Data, LabelGivenProps } from './types';

export const SavingsGraph = ({
  data,
  lifetimeSavings,
  barIndex
}: {
  data: Data;
  lifetimeSavings: number;
  barIndex: number;
}) => {
  const screenSize = useScreenSize();

  const tickStyle = {
    fontFamily: "'Graphik-Regular', 'Arial', 'sans-serif'",
    ...(screenSize < ScreenSize.medium ? { fontSize: '9px' } : { fontSize: '13px' }),
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fill: colors.NIMBUS_2
  };

  const styleBars = (graphData: Data) =>
    graphData.map(({ label }, i) => {
      const colorPicker = (graphLabel: boolean | undefined, key: number) => {
        if (graphLabel) {
          if (key < 5) {
            return colors.CLEMENTINE;
          } else {
            return colors.LOGO_SHADOW;
          }
        } else {
          if (key < 5) {
            return colors.LIGHT_CLEMENTINE;
          } else {
            return colors.LIGHT_ALOE;
          }
        }
      };
      return <Cell fill={colorPicker(label, i)} key={i} />;
    });

  const altText = `Bar graph showing 10 bars. Each bar represents the lifetime savings that lenders in that percentile provide compared to the average lender. Your lender falls into bar ${barIndex} and ${
    lifetimeSavings > 0 ? 'costs' : 'saves'
  } customers $${Math.round(Math.abs(lifetimeSavings))} over the lifetime of their loan`;

  return (
    <Wrapper aria-label="Savings graph">
      <GraphTitle>Savings compared to the average lender</GraphTitle>
      <GraphWrapper id="graph" role="figure" aria-label={altText}>
        <ResponsiveContainer height={screenSize < ScreenSize.medium ? 320 : 500}>
          <BarChart
            margin={{ top: 70, bottom: 30, right: 20, left: 5 }}
            data={data}
            barSize={screenSize < ScreenSize.medium ? 15 : 24}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis
              axisLine={false}
              tickLine={false}
              tick={false}
              padding={
                screenSize < ScreenSize.medium ? { left: 20, right: 20 } : { left: 50, right: 50 }
              }
            />
            <YAxis
              domain={['dataMin', 'dataMax']}
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => formatCurrency(value)}
              tick={tickStyle}
              ticks={computeTicks(data)}
              aria-hidden
            />
            <Bar dataKey="savings" radius={[2, 2, 0, 0]}>
              {styleBars(data)}
              <LabelList
                dataKey="savings"
                content={(props) => (
                  <Label
                    props={props as LabelGivenProps}
                    data={data}
                    lifetimeSavings={lifetimeSavings}
                  />
                )}
              />
            </Bar>
            <ReferenceLine y={0} stroke={colors.PRIMARY_DISABLED} strokeWidth={2} />
          </BarChart>
        </ResponsiveContainer>
      </GraphWrapper>
      <LegendWrapper aria-label="legend">
        {[
          { color: colors.LIGHT_CLEMENTINE, text: 'Additional interest paid' },
          { color: colors.LIGHT_ALOE, text: 'Interest savings' }
        ].map((props, i) => (
          <LegendItem {...props} key={i} />
        ))}
      </LegendWrapper>
    </Wrapper>
  );
};
