import { ValueProps } from '@rategravity/marketing-components';
import {
  colors,
  ParagraphText,
  Subheading,
  SubheadingSans
} from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { valueProps } from '../../data/content/value-props';
import { valuePropsMetadata } from '../../data/images/value-props';

const HeaderStyle = createOwnUpStyle({
  padding: '55px 6% 0',
  textAlign: 'center',
  variants: {
    mediumAndDown: {
      padding: '55px 5px 0'
    }
  }
});

const HeaderSection = createOwnUpComponent(OwnUpBox, HeaderStyle);

const header = (
  <HeaderSection>
    <Subheading>We built a better way to shop for your mortgage</Subheading>
  </HeaderSection>
);

const catchphraseDivStyle = createOwnUpStyle({
  margin: '0 40px',
  variants: {
    smallAndDown: {
      margin: 0
    },
    large: {
      margin: '0 8%'
    }
  }
});

const CatchphraseWrapper = createOwnUpComponent('div', catchphraseDivStyle);

const textDivStyle = createOwnUpStyle({
  margin: '0 7px 40px',
  variants: {
    medium: {
      marginBottom: '-40px'
    },
    small: {
      margin: '0 55px -40px'
    },
    xsmall: {
      margin: '0 0 -12px'
    }
  }
});

const TextDiv = createOwnUpComponent('div', textDivStyle);

interface ValuePropsData {
  catchphrase: string;
  text: string;
  label: string;
}

export const StyledValueProps = ({ catchphrase, text, label }: ValuePropsData) => {
  return {
    catchphrase: (
      <CatchphraseWrapper>
        <SubheadingSans>{catchphrase}</SubheadingSans>
      </CatchphraseWrapper>
    ),
    text: (
      <TextDiv>
        <ParagraphText>{text}</ParagraphText>
      </TextDiv>
    ),
    label
  };
};

const assembledValueProps = valueProps.map(({ data }, i) => ({
  data: StyledValueProps(data),
  Image: valueProps[i].Image
}));

const valuePropsWrapperStyle = createOwnUpStyle({
  padding: '0 10%',
  backgroundColor: colors.WHITE,
  variants: {
    medium: {
      padding: '21px 23% 66px'
    },
    smallAndDown: {
      padding: '0 24px',
      paddingBottom: '46px'
    }
  }
});
const ValuePropsWrapper = createOwnUpComponent('div', valuePropsWrapperStyle);

export const ValuePropsComponent = () => (
  <ValuePropsWrapper>
    <ValueProps
      valueProps={assembledValueProps}
      metadata={valuePropsMetadata}
      header={header}
      ariaLabel="value prop"
      style={{ columnGap: '68px' }}
    />
  </ValuePropsWrapper>
);
