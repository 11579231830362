import { FOG_20 } from '@rategravity/own-up-component-library';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox,
  OwnUpModalController
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { DialogDisclosure } from 'reakit';
import FilterIconSVG from '../../images/filter-icon.svg';
import { colors } from '../../modules';

/**
 * Styling that will underpin (most of) the buttons. Some properties will are assigned 'unset', in order to remove the defaults that come in from the component library.
 */
const ButtonStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_MEDIUM,
  fontFamily: "'Graphik-Medium', 'Arial', 'sans-serif'",
  backgroundColor: 'transparent',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: '25px',
  boxSizing: 'border-box',
  fontSize: '15px',
  height: 'unset',
  lineHeight: '120%',
  minWidth: 'unset',
  paddingTop: 0,
  textAlign: 'center',
  textTransform: 'unset',
  transition: '0.7s', // Most buttons have a hover state.
  whiteSpace: 'unset'
});

const CallToActionButtonStyle = createOwnUpStyle({
  ...ButtonStyle,
  backgroundColor: colors.BLUE,
  borderColor: colors.BLUE,
  borderRadius: '50px',
  borderWidth: '3px',
  color: FOG_20,
  letterSpacing: '.5px',
  padding: '16px 0',
  ':hover': {
    backgroundColor: colors.CLASSIC_BLUE,
    borderColor: colors.CLASSIC_BLUE
  }
});

export const CallToActionButton = createOwnUpComponent(OwnUpBox, CallToActionButtonStyle);

const CallToActionButtonWrapperStyle = createOwnUpStyle({
  margin: '35px auto',
  width: '100%',
  variants: {
    large: {
      maxWidth: '275px'
    }
  }
});

export const CallToActionButtonWrapper = createOwnUpComponent(
  OwnUpBox,
  CallToActionButtonWrapperStyle
);

const FilterAssumptionsButtonStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_MEDIUM,
  alignItems: 'center',
  backgroundColor: colors.WHITE,
  border: `1px solid ${colors.GREY}`,
  borderRadius: '3px',
  color: colors.PRIMARY,
  cursor: 'pointer',
  display: 'flex',
  fontSize: '16px',
  fontWeight: 500,
  height: '55px',
  justifyContent: 'center',
  margin: '15px auto',
  position: 'relative',
  width: '100%'
});

const FilterAssumptionsButton = createOwnUpComponent(
  DialogDisclosure,
  FilterAssumptionsButtonStyle
);

const FilterIconWrapperStyle = createOwnUpStyle({
  backgroundImage: `url(${FilterIconSVG})`,
  backgroundRepeat: 'no-repeat, no-repeat',
  backgroundSize: 'cover',
  height: '32px',
  left: '15px',
  position: 'absolute',
  width: '34px'
});

const FilterIconWrapper = createOwnUpComponent('div', FilterIconWrapperStyle);

export const FilterButton = ({ modalController }: { modalController: OwnUpModalController }) => (
  <FilterAssumptionsButton onClick={modalController.toggle}>
    <FilterIconWrapper />
    Filters
  </FilterAssumptionsButton>
);
