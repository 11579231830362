import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox,
  OwnUpButton
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { colors } from '../../../modules';

const ResetAndDoneWrapperStyle = createOwnUpStyle({
  alignItems: 'center',
  backgroundColor: colors.WHITE,
  borderTop: `1px solid ${colors.GREY}`,
  display: 'flex',
  height: '92px',
  justifyContent: 'space-evenly',
  padding: '0 15px',
  flexShrink: 0,
  variants: {
    large: {
      backgroundColor: 'unset',
      borderTop: 'none',
      width: '375px',
      margin: '0 auto',
      paddingBottom: '20px'
    }
  }
});

const ResetAndDoneWrapper = createOwnUpComponent(OwnUpBox, ResetAndDoneWrapperStyle);

const ButtonStyle = createOwnUpStyle({
  width: '156px',
  variants: {
    xsmall: {
      minWidth: '120px'
    }
  }
});

const Button = createOwnUpComponent(OwnUpButton, ButtonStyle);

export const ResetAndDoneButtons = ({
  reset,
  done,
  disabled = false
}: {
  reset: () => void;
  done: () => void;
  disabled?: boolean;
}) => (
  <ResetAndDoneWrapper>
    <Button variant={['alt', 'heroic']} onClick={reset}>
      Reset
    </Button>
    <Button disabled={disabled} variant="heroic" onClick={done}>
      Done
    </Button>
  </ResetAndDoneWrapper>
);
