import { useTrackingInstance } from '@rategravity/1pt-lib';
import { FOG_20 } from '@rategravity/own-up-component-library';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox,
  OwnUpModalController,
  OwnUpStyledIcon,
  OwnUpText,
  zIndex
} from '@rategravity/own-up-component-library-legacy';
import { CancelX } from '@rategravity/own-up-component-library-legacy/components/images/icons/svgs';
import React, { useContext, useEffect, useState } from 'react';
import { Dialog } from 'reakit';
import { CSSObject } from 'styled-components';
import {
  ErrorContext,
  FeedbackContext,
  InputsContext,
  LoadingContext,
  RequestContext
} from '../../../context';
import { PartialParameters } from '../../../context/types';
import FilterIconSVG from '../../../images/filter-icon.svg';
import { colors, getResults, isEqual } from '../../../modules';
import { LoanTriad } from '../../loan-triad';
import { StateAndLender } from '../../state-lender';
import { ResetAndDoneButtons } from '../reset-and-done';

const StickyHeaderStyle: CSSObject = {
  height: '64px',
  padding: '16px',
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  zIndex: zIndex.AppHeader,
  backgroundColor: colors.WHITE,
  width: '100%',
  boxSizing: 'border-box'
};

const ModalWrapperStyle = createOwnUpStyle({
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  zIndex: zIndex.ModalWindow,
  overflow: 'auto',
  backgroundColor: FOG_20,
  display: 'flex',
  flexDirection: 'column'
});

const ModalWrapper = createOwnUpComponent(Dialog, ModalWrapperStyle);

const ContentWrapperStyle = createOwnUpStyle({
  flex: '1 0 auto',
  variants: {
    large: {
      flex: 'unset'
    }
  }
});

const ContentWrapper = createOwnUpComponent(OwnUpBox, ContentWrapperStyle);

const FormWrapperStyle = createOwnUpStyle({
  maxWidth: '375px',
  margin: 'auto',
  padding: '84px 24px 20px',
  variants: {
    large: {
      padding: '94px 24px 20px'
    }
  }
});

const FormWrapper = createOwnUpComponent(OwnUpBox, FormWrapperStyle);

const IconWrapper = createOwnUpComponent('button', {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  height: '24px',
  width: '24px',
  position: 'absolute',
  right: '24px'
});

const iconStyle = {
  height: '24px',
  width: '24px',
  position: 'absolute',
  top: 0,
  left: 0
};

const FiltersText = createOwnUpComponent(OwnUpText, {
  ...fonts.GRAPHIK_MEDIUM,
  paddingLeft: '21px'
});

const HeaderStyle = createOwnUpStyle({
  ...StickyHeaderStyle,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
});

const Header = createOwnUpComponent(OwnUpBox, HeaderStyle);

export const FiltersModal = ({ modalController }: { modalController: OwnUpModalController }) => {
  const [disabled, setDisabled] = useState(true);

  const { request, setRequest } = useContext(RequestContext);
  const { inputs, setInputs } = useContext(InputsContext);
  const { setFeedback } = useContext(FeedbackContext);
  const { setError } = useContext(ErrorContext);
  const { startLoading, endLoading } = useContext(LoadingContext);
  const trackingInstance = useTrackingInstance();

  useEffect(() => {
    setDisabled(inputs.state === undefined || inputs.lei === undefined);
  }, [inputs]);

  const submit = async (modalInputs: PartialParameters) => {
    const { email, ...restRequest } = request;
    trackingInstance.track('clickedAssumptionsCTA-lenderGrader');
    trackingInstance.track('Lender Grader Assumptions CTA Clicked');

    // close modal
    modalController.hide();

    // compare local state to request context
    // if the states don't match, submit a new request
    if (!isEqual(modalInputs, restRequest)) {
      await getResults(inputs, setRequest, setFeedback, setError, startLoading, endLoading);
    }
  };

  const closeModal = () => {
    // close modal
    modalController.hide();
    // reset inputs state to what it was when the modal was opened
    setInputs((prevState: PartialParameters) => ({ ...prevState, ...request }));
  };

  const reset = () => {
    setInputs((prevState: PartialParameters) => ({
      ...prevState,
      ...request,
      loanSize: 2, // 3rd (middle) quintile
      loanTerm: 0, // 30 yr fixed
      loanType: 0 // Conventional,
    }));
  };

  const onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      // reset inputs state to what it was when the modal was opened
      setInputs((prevState: PartialParameters) => ({
        ...prevState,
        ...request
      }));
    }
  };

  return (
    <ModalWrapper {...modalController.state} aria-label="Filters" onKeyDown={onKeydown}>
      <ContentWrapper>
        <Header>
          {/* empty alt string indicates the image is decorative, ignored by assistive technologies */}
          <img src={FilterIconSVG} height={30} alt="" />
          <FiltersText>Filters</FiltersText>
          <IconWrapper onClick={closeModal} aria-label="close">
            <OwnUpStyledIcon style={iconStyle}>
              <CancelX color={colors.PRIMARY_ALT} strokeWidth={1} />
            </OwnUpStyledIcon>
          </IconWrapper>
        </Header>
        <FormWrapper>
          <StateAndLender />
          <LoanTriad />
        </FormWrapper>
      </ContentWrapper>
      <ResetAndDoneButtons reset={reset} done={() => submit(inputs)} disabled={disabled} />
    </ModalWrapper>
  );
};
