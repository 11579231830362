import { useTrackingInstance } from '@rategravity/1pt-lib';
import { ShopCTA, SwooshDivider, wwwConverter } from '@rategravity/marketing-components';
import { FOG_20 } from '@rategravity/own-up-component-library';
import {
  ScreenSize,
  useModalController,
  useScreenSize
} from '@rategravity/own-up-component-library-legacy';
import React, { useContext } from 'react';
import { FilterButton } from '../components/button';
import { FullWidthField } from '../components/full-width-field';
import { Layout } from '../components/layout';
import {
  ErrorView,
  FiltersModal,
  Methodology,
  SavingsGraph,
  ShopOurMarketplaceCTA,
  Summary
} from '../components/results';
import { transformGraphData } from '../components/results/visualization/helpers';
import { ValuePropsComponent } from '../components/value-props';
import { ErrorContext, FeedbackContext } from '../context';
import { colors } from '../modules';

export const Results = () => {
  const { feedback } = useContext(FeedbackContext);
  const { error } = useContext(ErrorContext);
  const { data, lifetimeSavings, barIndex } = transformGraphData(feedback);

  const modalController = useModalController();
  const screenSize = useScreenSize();
  const trackingInstance = useTrackingInstance();

  if (error) {
    trackingInstance.track('errorState-lenderGrader', { error });
    trackingInstance.track('Lender Grader Error Caught', { error });
    return (
      <Layout>
        <FiltersModal modalController={modalController} />
        <ErrorView error={error} modalController={modalController} />
      </Layout>
    );
  } else {
    return (
      <Layout>
        <FiltersModal modalController={modalController} />
        {screenSize < ScreenSize.large ? <FilterButton modalController={modalController} /> : null}
        <Summary {...{ data: feedback, modalController }} />
        {screenSize === ScreenSize.large ? (
          <FilterButton modalController={modalController} />
        ) : null}
        <FullWidthField backgroundColor={FOG_20}>
          <div style={{ maxWidth: '600px', margin: 'auto' }}>
            <SavingsGraph {...{ data, lifetimeSavings, barIndex }} />
          </div>
        </FullWidthField>
        <ShopOurMarketplaceCTA />
        <SwooshDivider
          topColor={FOG_20}
          bottomColor={colors.WHITE}
          desktopMaxWidth={600}
          mobileLateralGutter={25}
        />
        <FullWidthField backgroundColor={colors.WHITE}>
          <ValuePropsComponent />
        </FullWidthField>
        <ShopCTA
          desktopMaxWidth={600}
          mobileLateralGutter={25}
          www={wwwConverter(process.env.GATSBY_WWW)}
        />
        <Methodology />
      </Layout>
    );
  }
};

export default Results;
