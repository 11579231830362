import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox,
  OwnUpHeader,
  OwnUpModalController,
  OwnUpText
} from '@rategravity/own-up-component-library-legacy';
import { Link } from 'gatsby';
import React from 'react';
import { ErrorLiterals } from '../../../context';
import { CallToActionButton, CallToActionButtonWrapper, FilterButton } from '../../button';
import ErrorIcon from './error.svg';

const errorText = {
  networkError:
    'There was an error with your request. Please try again or learn more about Own Up and our lender network.',
  notEnoughInformation:
    "We don't have enough information on this scenario for this lender. Try changing one of the filters."
};

const ErrorWrapperStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',
  maxWidth: '290px',
  padding: '46px 0'
});

const ErrorWrapper = createOwnUpComponent(OwnUpBox, ErrorWrapperStyle);

const HeaderTextStyle = createOwnUpStyle({
  fontSize: '19px',
  margin: '15px 0',
  textAlign: 'center'
});

const HeaderText = createOwnUpComponent(OwnUpHeader, HeaderTextStyle);

const TextStyle = createOwnUpStyle({
  fontSize: '15px',
  letterSpacing: '0.2px',
  lineHeight: '23px',
  maxWidth: '290px',
  textAlign: 'center'
});

const Text = createOwnUpComponent(OwnUpText, TextStyle);

export const ErrorView = ({
  error,
  modalController
}: {
  error: ErrorLiterals;
  modalController: OwnUpModalController;
}) => {
  return (
    <ErrorWrapper>
      <img alt={'red exclamation mark inside a red circle'} src={ErrorIcon} height={44} />
      <HeaderText variant="section" level={1}>
        Oops there was a problem!
      </HeaderText>
      <Text>{errorText[error]}</Text>
      {error === 'networkError' ? (
        <CallToActionButtonWrapper>
          <Link to="/" style={{ textDecoration: 'none', height: '56px' }}>
            <CallToActionButton>Back To Previous Page</CallToActionButton>
          </Link>
        </CallToActionButtonWrapper>
      ) : (
        <FilterButton modalController={modalController} />
      )}
    </ErrorWrapper>
  );
};
