import { Feedback } from '../../../context/types';
import { Data } from './types';

export const transformGraphData = (data: Feedback) => {
  const index = Math.floor(data.lender.percentile * 10);
  const graphData = data.deciles.map((savings) => ({
    savings
  }));
  const graphDataWithLabel = graphData.map((d, i) => (i === index ? { ...d, label: true } : d));
  return {
    data: graphDataWithLabel,
    lifetimeSavings: data.lender.interestSpread.lifetime,
    barIndex: index + 1 // used for graph alt text
  };
};

/**
 * Returns an array of seven digits that represent the tick marks
 * for the visualization. For appearance's sake, the tick marks are
 * divisible by 1000. Moreover, the highest tick mark is divisible
 * by 3000, so that trisecting the y-axis will yield equally sized bars.
 */
export const computeTicks = (data: Data) => {
  const round = (n: number) => Math.ceil(n / 3000) * 3000;

  const max = Math.max(...data.map(({ savings }) => Math.abs(savings || 0)));
  const roundMax = round(max);

  const positiveValues = [roundMax, (roundMax * 2) / 3, (roundMax * 1) / 3];

  return [...positiveValues, 0, ...positiveValues.map((n) => -n)].sort((a, b) => b - a);
};
