import { Metadata } from '@rategravity/marketing-components';

export const valuePropsMetadata: Record<string, Metadata> = {
  'handshake.svg': {
    alt: 'Icon of a handshake, conveying an agreement to help our customers find the best deal'
  },
  'money.svg': {
    alt: 'Icon with two coins representing expenses'
  },
  'scale.svg': {
    alt: 'Icon of a scale, showing how Own Up compares different interest rates and costs'
  }
};
